import React from "react";
import Birthday from "../components/Birthday.js";
import TextScrambler from "../components/TextScrambler.js";

import Portrait from "../images/portrait.jpg";

import styled, { keyframes } from "styled-components";
import { fadeInDown, fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInDown}`;
const bounceAnimation2 = keyframes`${fadeInUp}`;

const FadeDownDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

const FadeUpDiv = styled.div`
  animation: 1s ${bounceAnimation2};
`;

export default function About() {
  const intro = `Hi, I'm JP. Nope, it doesn't stand for "John Paul" (close though).
              Thanks for dropping by! I've written some words below, but the
              best way to get to know me is by reaching out or finding me in any
              of my natural habitats.`;

  const bio0 = `I'm an engineering wrapper around a product & strategist core, or a specialist wrapper around a generalist core, as some may say. Not even two technical degrees could shake me from my ways. I'm fascinated by technology. While the engineering bits do excite me, what drives me to technology time and time again is the story that can be written by technology, and the journey tech takes to become more than just the sum of its parts.`;

  const bio1 = `I've had some incredible experiences with some incredible people (check the 'work' tab at the top of the page for more details) and have always kept an eye on the cutting edge, AI, and policy. Some of my formal experience includes: full stack engineering, growth, ML, web applications, backend services & infrastructure, product management, and research. Outside of that, I love writing (mostly on non-engineering topics), history, markets, and have a passion for teaching.`;

  const bio2 = `I like working on hard things with good people. That's it -- simple, isn't it.`;

  const bio3 = `My core interests are curiosity, learning, complexity, and storytelling.`;

  const bio4 = `For a large portion of my life, my sole job was to not let a ball hit the floor. I was a D1 athlete on the Stanford Men's Volleyball team as an outside hitter, and have previously been a member of USA volleyball, competing internationally on several occasions. The privilege of representing the United States was an incredible honor, and an experience that has given me an immense pride and respect for this country and what it represents.`;

  const bio5 = `When I was 14 years old, I was diagnosed with Type-1 diabetes. Since midway through my high school career, I have proudly served as a mentor for young and newly diagnosed Type-1 diabetics. Please feel more than welcome to reach out for anything related to diabetes, I am always happy to share my experiences and knowledge as a diabetic with those who are interested. My advice in a few sentences: diabetes sucks, and it will always suck. It's invisible, and no one will ever know just how much it sucks other than you (and other T1Ds). Over time, it will suck less -- yet it will still suck, and at least for the next few years that won't change. However, as you grow into the person you want to become, the other aspects of what make you "you" will begin to dwarf the suckiness of diabetes. Imagine your life today is a circle, and diabetes is a circle within that circle. Right now, it may feel like diabetes fills up almost all of your life circle -- because it probably does. It's scary, it's new, it's uncertain, and it's unrelenting. Day by day, as you begin to learn, manage, and overcome T1D, your life circle will continue to grow as you grow. Diabetes will still be there, but now in comparison to the person you have become, it will only be a small dot in the circle of you. So, my advice to conquer diabetes: don't let it change the person you want to become. Reject the life you think that diabetes will limit you to. Other than a smallest subset of dreams (astronauts, fighter pilots, & a few others), the world is still yours in the way that it was yours before T1D. Be proud of what you have overcome, and know that just by being alive and here today, you are already a champion. You are stronger than you think, and use that strength to make the world a better place.`;

  const bio6 = `I'm an active being, and an avid surfer and skier. I've grew up wanting to be an astronaut (literally one of the only professions I can't be as a Type 1 Diabetic), and have since satisfied that urge by spending way too much time reading, watching, and thinking about the final frontier. I'm a conversationalist, and therefore a podcast fanatic. I'm a music fan (like many), and like to think that I have superior taste than most in this arena. Cars have always been a cental passion of mine, and I've rebuilt several with my Dad over the years. Following this theme, I'm a Formula 1 nerd, and haven't missed a race in the past few seasons. Other sports I'm into: volleyball, golf (I'm terrible), and pickleball (is it even a sport?). One of my goals for the next 10 years: visit 6/7 continents. Antartica doesn't make the cut (for now), the penguins will have to wait. In summation: resident armchair philosopher, self-proclaimed Settlers of Catan champion, washed up college athlete, and futurist.`;

  return (
    <div className="page">
      <FadeDownDiv>
        <div className="title">
          <TextScrambler txt="About me" />
        </div>
      </FadeDownDiv>
      <div className="divider">
        <FadeUpDiv>
          <img className="photo" alt="me" src={Portrait} />

          <div className="my-info">
            <div className="info-item">
              <TextScrambler txt={intro} />
            </div>

            <div className="info-item">
              <Birthday />
            </div>

            <div className="info-item">
              <TextScrambler txt={bio0} />
            </div>

            <div className="info-item">
              <TextScrambler txt={bio1} />
            </div>

            <div className="info-item">
              <TextScrambler txt={bio2} />
            </div>

            <div className="info-item">
              <TextScrambler txt={bio3} />
            </div>

            <div className="info-item">
              <TextScrambler txt={bio4} />
            </div>

            <div className="info-item">
              <TextScrambler txt={bio5} />
            </div>

            <div className="info-item">
              <TextScrambler txt={bio6} />
            </div>
          </div>
        </FadeUpDiv>
      </div>
    </div>
  );
}
