import React from "react";
import { useScramble } from "use-scramble";

export default function TextScrambler({ txt }) {
  const { ref } = useScramble({
    text: txt,
    speed: 1,
    tick: 1,
    step: 10,
    scramble: 10,
    seed: 2,
    chance: 0.75,
    overdrive: false,
    overflow: true,
  });
  return <div ref={ref} />;
}
