import React from "react";

import TextScrambler from "../components/TextScrambler.js";

import styled, { keyframes } from "styled-components";
import { fadeInDown } from "react-animations";

const bounceAnimation = keyframes`${fadeInDown}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

export default function NoMatch() {
  return (
    <BouncyDiv className="page">
      <div className="title">
        <TextScrambler txt="Sorry, no page found" />
      </div>
    </BouncyDiv>
  );
}
