import React from "react";
import TextScrambler from "./TextScrambler.js";
import { useNavigate } from "react-router-dom";

import styled, { keyframes } from "styled-components";
import { fadeInDown } from "react-animations";

const bounceAnimation = keyframes`${fadeInDown}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

export default function Header() {
  const navigate = useNavigate();

  function navigateTo(path) {
    navigate(path);
  }

  return (
    <BouncyDiv>
      <div className="header-wrap">
        <div className="my-name" onClick={() => navigateTo("/")}>
          <TextScrambler txt="JP Reilly" />
        </div>

        <div className="header-right-menu">
          <div
            className="header-item-left"
            onClick={() => navigateTo("/about")}
          >
            <TextScrambler txt="About" />
          </div>
          <div className="header-item" onClick={() => navigateTo("/work")}>
            <TextScrambler txt="Work" />
          </div>
          <div className="header-item" onClick={() => navigateTo("/other")}>
            <TextScrambler txt="Listen" />
          </div>
          <div className="header-item" onClick={() => navigateTo("/contact")}>
            <TextScrambler txt="Contact" />
          </div>
        </div>
      </div>
    </BouncyDiv>
  );
}
