import React from "react";
import "./App.scss";

import AppRoutes from "./Routes.js";
import { AppProvider } from "./contexts/AppContext.js";

function App() {
  console.log(
    `
     ___  _______    ______    _______  ___   ___      ___      __   __ 
    |   ||       |  |    _ |  |       ||   | |   |    |   |    |  | |  |
    |   ||    _  |  |   | ||  |    ___||   | |   |    |   |    |  |_|  |
    |   ||   |_| |  |   |_||_ |   |___ |   | |   |    |   |    |       |
 ___|   ||    ___|  |    __  ||    ___||   | |   |___ |   |___ |_     _|
|       ||   |      |   |  | ||   |___ |   | |       ||       |  |   |  
|_______||___|      |___|  |_||_______||___| |_______||_______|  |___|  
`
  );
  console.log(
    "To see how I made this website, visit: http://tinyurl.com/fh5wj7sc"
  );
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
