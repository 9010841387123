import React from "react";
import TextScrambler from "../components/TextScrambler.js";

import styled, { keyframes } from "styled-components";
import { fadeInDown, fadeInUp } from "react-animations";

import PearVC from "../images/pearvc.png";
import EightVC from "../images/8vc.jpg";
import Contrary from "../images/contrary.png";
import Tidepool from "../images/tidepool.png";
import Gatsby from "../images/gatsby.ico";
import AVP from "../images/avpacademy.png";
import STVP from "../images/stvpp.png";
import Transform from "../images/transform.png";
import dbt from "../images/dbt.png";

const bounceAnimation = keyframes`${fadeInDown}`;
const bounceAnimation2 = keyframes`${fadeInUp}`;

const FadeDownDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

const FadeUpDiv = styled.div`
  animation: 1s ${bounceAnimation2};
`;

export default function Work() {
  return (
    <>
      <FadeDownDiv>
        <a
          href="https://drive.google.com/file/d/1wRD9MyIz0hfv0wecstH3JsuygOjSFmM1/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="title">
            <TextScrambler txt="Work" />
          </div>
          <div className="linkClick">
            <TextScrambler
              className="linkClick"
              txt="Click here to open my full resume (may be outdated)"
            />
          </div>
        </a>
      </FadeDownDiv>
      <FadeUpDiv>
        <div className="grid-container-1">
          <a
            href="https://www.getdbt.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="photo-work-1" alt="dbt labs" src={dbt} />
          </a>
          <div className="description-1">
            <div className="work-title">
              <TextScrambler txt="dbt Labs" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Software Engineer, Growth & Monetization _ 2023 - 2024" />
            </div>
          </div>
        </div>

        <div className="grid-container-2">
          <div className="description-2">
            <div className="work-title">
              <TextScrambler txt="Transform (acquired by dbt labs)" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Software Engineer _ 2022 - 2023" />
            </div>
          </div>
          <a
            href="https://www.transform.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="photo-work-1" alt="transform" src={Transform} />
          </a>
        </div>

        <div className="grid-container-1">
          <a
            href="https://www.gatsby.events/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="photo-work-1" alt="gatsby labs" src={Gatsby} />
          </a>
          <div className="description-1">
            <div className="work-title">
              <TextScrambler txt="Gatsby Labs" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Data Scientist _ Summer 2021 - Fall 2021" />
            </div>
          </div>
        </div>

        <div className="grid-container-2">
          <div className="description-2">
            <div className="work-title">
              <TextScrambler txt="Stanford Technology Venture Program" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Research Assistant _ Spring 2021 - Fall 2022" />
            </div>
          </div>
          <a
            href="https://stvp.stanford.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="photo-work-2" alt="STVP" src={STVP} />
          </a>
        </div>

        <div className="grid-container-1">
          <a
            href="https://www.pear.vc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="photo-work-1" alt="pear vc" src={PearVC} />
          </a>
          <div className="description-1">
            <div className="work-title">
              <TextScrambler txt="Pear VC" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Investment Fellow _ Fall 2020 - Fall 2021" />
            </div>
          </div>
        </div>

        <div className="grid-container-2">
          <div className="description-2">
            <div className="work-title">
              <TextScrambler txt="Contrary Capital" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Talent Fellow _ Summer 2020 - Spring 2023" />
            </div>
          </div>
          <a
            href="https://contrarycap.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="photo-work-2" alt="contrary" src={Contrary} />
          </a>
        </div>

        <div className="grid-container-1">
          <a
            href="https://www.gatsby.events/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="photo-work-1" alt="gatsby" src={Gatsby} />
          </a>
          <div className="description-1">
            <div className="work-title">
              <TextScrambler txt="Gatsby Labs" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Software Engineer _ Summer 2020" />
            </div>
          </div>
        </div>

        <div className="grid-container-2">
          <div className="description-2">
            <div className="work-title">
              <TextScrambler txt="8VC" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Fellow _ Summer 2020" />
            </div>
          </div>
          <a href="https://8vc.com/" target="_blank" rel="noopener noreferrer">
            <img className="photo-work-2" alt="8VC" src={EightVC} />
          </a>
        </div>

        <div className="grid-container-1">
          <a
            href="https://www.tidepool.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="photo-work-1" alt="tidepool" src={Tidepool} />
          </a>
          <div className="description-1">
            <div className="work-title">
              <TextScrambler txt="Tidepool" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Software Engineer _ Summer 2019" />
            </div>
          </div>
        </div>

        <div className="grid-container-2">
          <div className="description-2">
            <div className="work-title">
              <TextScrambler txt="AVP Academy" />
            </div>
            <div className="work-position">
              <TextScrambler txt="Web design & Project Management _ Summer 2018" />
            </div>
          </div>
          <a
            href="https://www.avpacademy.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="photo-work-2" alt="avp" src={AVP} />
          </a>
        </div>
      </FadeUpDiv>
    </>
  );
}
