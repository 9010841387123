import React from "react";
import TextScrambler from "../components/TextScrambler.js";

import styled, { keyframes } from "styled-components";
import { fadeInDown, fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInDown}`;
const bounceAnimation2 = keyframes`${fadeInUp}`;

const FadeDownDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

const FadeUpDiv = styled.div`
  animation: 1s ${bounceAnimation2};
`;

export default function Contact() {
  return (
    <div className="page">
      <FadeDownDiv>
        <div className="title">
          <TextScrambler txt="Contact me" />
        </div>
      </FadeDownDiv>
      <FadeUpDiv>
        <div className="social-buttons">
          <ul>
            <li>
              <a
                className="twitter"
                href="https://twitter.com/japesreilly"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                className="linkedin"
                href="https://www.linkedin.com/in/jpreilly1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                className="email"
                href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=jreillymb@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                className="github"
                href="https://github.com/jpreillymb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <i className="fa fa-github" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a
                className="instagram"
                href="https://www.instagram.com/fatjapes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </FadeUpDiv>
    </div>
  );
}
