import React from "react";
import TextScrambler from "../components/TextScrambler.js";

import styled, { keyframes } from "styled-components";
import { fadeInDown, fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInDown}`;
const bounceAnimation2 = keyframes`${fadeInUp}`;

const FadeDownDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

const FadeUpDiv = styled.div`
  animation: 1s ${bounceAnimation2};
`;

export default function Blog() {
  return (
    <div className="page">
      <FadeDownDiv>
        <div className="title">
          <TextScrambler txt="What I'm listening to" />
        </div>
      </FadeDownDiv>
      <div className="divider">
        <FadeUpDiv>
          <div className="my-info">
            <div className="info-item">
              <TextScrambler txt="To think..." />
            </div>

            {[
              {
                name: "Uncommon Knowledge",
                url: "https://podcasts.apple.com/us/podcast/uncommon-knowledge/id1378389941",
              },
              {
                name: "Huberman Lab",
                url: "https://podcasts.apple.com/us/podcast/huberman-lab/id1545953110",
              },
              {
                name: "Acquired",
                url: "https://podcasts.apple.com/us/podcast/acquired/id1050462261",
              },
              {
                name: "All In Podcast",
                url: "https://podcasts.apple.com/us/podcast/all-in-with-chamath-jason-sacks-friedberg/id1502871393",
              },
              {
                name: "Honestly with Bari Weiss",
                url: "https://podcasts.apple.com/us/podcast/honestly-with-bari-weiss/id1570872415",
              },
              {
                name: "Conversations with Tyler",
                url: "https://podcasts.apple.com/us/podcast/conversations-with-tyler/id983795625",
              },
              {
                name: "Lex Fridman Podcast",
                url: "https://podcasts.apple.com/us/podcast/lex-fridman-podcast/id1434243584",
              },
              {
                name: "Making Sense with Sam Harris",
                url: "https://podcasts.apple.com/us/podcast/making-sense-with-sam-harris/id733163012",
              },
              {
                name: "Conversations with Coleman",
                url: "https://podcasts.apple.com/us/podcast/conversations-with-coleman/id1716338488",
              },
              {
                name: "This Week in Startups",
                url: "https://podcasts.apple.com/us/podcast/this-week-in-startups/id315114957",
              },
              {
                name: "BG2Pod",
                url: "https://podcasts.apple.com/us/podcast/bg2pod-with-brad-gerstner-and-bill-gurley/id1727278168",
              },
              {
                name: "Every Turpentine Podcast (Upstream, The Cognitive Revolution, etc etc)",
                url: "https://podcasts.apple.com/us/podcast/econ-102-with-noah-smith-and-erik-torenberg/id1696419056",
              },
              {
                name: "Dan Carlin's Hardcore History",
                url: "https://podcasts.apple.com/us/podcast/dan-carlins-hardcore-history/id173001861",
              },
              {
                name: "Sean Carroll's Mindscape",
                url: "https://podcasts.apple.com/us/podcast/sean-carrolls-mindscape-science-society-philosophy/id1406534739",
              },
              {
                name: "Modern Wisdom",
                url: "https://podcasts.apple.com/us/podcast/modern-wisdom/id1347973549",
              },
              {
                name: "American Optimist",
                url: "https://podcasts.apple.com/us/podcast/joe-lonsdale-american-optimist/id1573141757",
              },
              {
                name: "The Prof G Pod",
                url: "https://podcasts.apple.com/us/podcast/the-prof-g-pod-with-scott-galloway/id1498802610",
              },
              {
                name: "The Ricochet Podcast",
                url: "https://podcasts.apple.com/us/podcast/the-ricochet-podcast/id699723863",
              },
              {
                name: "The Portal by Eric Weinstein",
                url: "https://podcasts.apple.com/us/podcast/the-portal/id1469999563",
              },
              {
                name: "Dispatches from The Well",
                url: "https://podcasts.apple.com/us/podcast/dispatches-from-the-well/id1716445883",
              },
              {
                name: "Pirate Wires",
                url: "https://podcasts.apple.com/us/podcast/pirate-wires/id1687530630",
              },
            ].map((podcast, index) => (
              <div
                key={index}
                className="text-color-white"
                onClick={() =>
                  window.open(podcast.url, "_blank", "noopener,noreferrer")
                }
              >
                <TextScrambler txt={podcast.name} />
              </div>
            ))}

            <div className="text-color-white-end">
              <TextScrambler txt="... and a few others" />
            </div>
          </div>
          <div className="my-info">
            <div className="info-item">
              <TextScrambler txt="To jam..." />
            </div>
            <div className="playlists">
              <div className="playlist-item">
                <iframe
                  style={{ borderRadius: "12px", border: "0px" }}
                  src="https://open.spotify.com/embed/playlist/37i9dQZF1DWV5vqkTng2MA?utm_source=generator"
                  width="100%"
                  height="352"
                  allowFullScreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                  title="playlist"
                ></iframe>
              </div>
              <div className="playlist-item">
                <iframe
                  style={{ borderRadius: "12px", border: "0px" }}
                  src="https://open.spotify.com/embed/playlist/37i9dQZF1DXbITWG1ZJKYt?utm_source=generator"
                  width="100%"
                  height="352"
                  allowFullScreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                  title="playlist"
                ></iframe>
              </div>
              <div className="playlist-item">
                <iframe
                  style={{ borderRadius: "12px", border: "0px" }}
                  src="https://open.spotify.com/embed/playlist/37i9dQZF1DWUE76cNNotSg?utm_source=generator"
                  width="100%"
                  height="352"
                  allowFullScreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                  title="playlist"
                ></iframe>
              </div>
            </div>
          </div>
        </FadeUpDiv>
      </div>
    </div>
  );
}
