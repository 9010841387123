import React from "react";
import TextScrambler from "./TextScrambler.js";

import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";

const bounceAnimation = keyframes`${fadeInUp}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

export default function Footer() {
  return (
    <BouncyDiv>
      <div className="footer-wrap">
        <TextScrambler txt="Made in React by JP 🚀" />
      </div>
    </BouncyDiv>
  );
}
