import React, { useCallback, useMemo, useState } from 'react';

const AppContext = React.createContext();

export function AppProvider(props) {

  const [isSplashPage, _setSplashPage] = useState(false);
  const setSplashPage = useCallback(_setSplashPage, [_setSplashPage]);

  const getMemoizedSetters = useMemo(() => {
    return {
      setSplashPage
    };
  }, [setSplashPage]);

  // Merge memoized setters with non-memoable vars
  const getApi = () => {
    return {
      isSplashPage,
      ...getMemoizedSetters
    };
  };

  return (
    <AppContext.Provider value={getApi()}>
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContext;
