import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";

const bounceAnimation = keyframes`${fadeIn}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

export default function Home() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <BouncyDiv className="page">
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          particles: {
            number: {
              value: 100,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
          },
          interactivity: {
            events: {
              onHover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
          fullScreen: {
            enable: true,
            zIndex: 0,
          },
        }}
        style={{
          minHeight: "100vh",
        }}
      />
    </BouncyDiv>
  );
}
