import React, { useEffect, useState } from "react";

export default function Birthday() {
  const [secondsOld, setSecondsOld] = useState(
    Math.round(
      new Date().getTime() / 1000 - new Date("March 31, 1999").getTime() / 1000
    )
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsOld(
        Math.round(
          new Date().getTime() / 1000 -
            new Date("March 31, 1999").getTime() / 1000
        )
      );
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <div>I am {secondsOld} seconds old</div>;
}
